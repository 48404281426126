import Vue from 'vue'
import VueRouter from 'vue-router'
import * as Sentry from '@sentry/vue'
import {BrowserTracing} from '@sentry/tracing'

import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  scrollBehavior: () => ({x: 0, y: 0}),
  routes,
  history: true,
  // Leave these as they are and change in quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  mode: process.env.VUE_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE,
})

Sentry.init({
  Vue,
  dsn: process.env.SENTRY_URL,
  release: process.env.SENTRY_RELEASE,
  environment: `${process.env.SENTRY_ENVIRONMENT}-frontend`,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'shipstream.io', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
})
export default function({store}) {
  router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
    const authToken = store.getters['Auth/getAuthToken']
    const hasAuth = authToken !== null && authToken !== ''
    if (process.env.DEBUGGING) console.log('Has Auth Token:', hasAuth)
    if (process.env.DEBUGGING) console.log('Routing to:', to.path)
    if (!process.env.USER_AUTH) {
      // Auth disabled or not relevant
      next()
    } else {
      const hubConnection = await store.getters['Hub/getHubConnection']
      if (to.name && to.path !== '/auth') {
        if ((requiresAuth && hasAuth) || !requiresAuth) {
          next()
          return
        }
      }
      if (to.path !== '/cookie-error' && hubConnection.code && hubConnection.code === 400) {
        next({name: 'CookieError'})
      } else if (hasAuth && to.path === '/auth') {
        // Redirect away from /auth url to hide jwt
        next({name: 'index'})
      } else {
        next({name: 'Connect'})
      }
    }
  })

  return router
}
