import Vue from 'vue'
import Vuex from 'vuex'
import Hub from './modules/hub'
import Auth from './modules/auth'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default new Vuex.Store({
  namespaced: true,
  name: 'global',
  modules: {
    Hub,
    Auth,
  },
  strict: process.env.DEV,
})
