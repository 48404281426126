/**
 * @class
 * @property {boolean} isLegacy
 * @property {string} name Client username
 * @property {string} machine Client machine name
 * @property {string} label Custom label (optional)
 * @property {string} socketId Key unique to this socket
 * @property {Device[]} devices
 * @property {AppType} appType
 * @property {boolean} isLoadingPrinterInfo
 */
class Client extends Object {
  iconName() {
    return this.isLegacy ? 'update' : this.appType === APP_TYPES.DeviceService ? 'storage' : 'computer'
  }

  title() {
    return this.isLegacy ? 'Legacy Client' : this.appType === APP_TYPES.DeviceService ? 'Device Service' : 'Device App'
  }
}

/**
 * @class
 * @property {string} clientLabel
 * @property {string} device_name
 * @property {DeviceType} device_type
 * @property {string} id
 * @property {string} machine
 * @property {DeviceOptions} options
 * @property {string} socketId
 * @property {string[]} tagsSelected
 * @property {string} uniqueId
 * @property {string} user
 */
class Device {
  constructor() {
    this.clientLabel = ''
    this.device_name = ''
    this.device_type = {}
    this.id = ''
    this.machine = ''
    this.options = {}
    this.socketId = ''
    this.tagsSelected = []
    this.uniqueId = ''
    this.user = ''
  }
}

/**
 * @class
 * @property {string} id
 * @property {string} icon
 * @property {string} name
 * @property {string} type_name
 */
class DeviceType extends Object {}

/**
 * @typedef {string} AppType
 */
/**
 * @enum {AppType}
 */
const APP_TYPES = Object.freeze({
  Legacy: 'leg',
  DeviceService: 'ds',
  DeviceApp: 'da',
})

/**
 * @class
 * @property {string} connectionType
 * @property {string} port
 * @property {string} rxTerminator
 * @property {string} txTerminator
 * TODO - add serial options
 */
class DeviceOptions extends Object {}

export {Client, Device, DeviceType, APP_TYPES}
