const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'index',
        component: () => import('pages/Home.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: 'view-logs',
        name: 'ViewLogs',
        component: () => import('pages/ViewLogs.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: 'connect',
        name: 'Connect',
        component: () => import('pages/Connect.vue'),
      },
      {
        path: 'downloads',
        name: 'Downloads',
        component: () => import('pages/Downloads.vue'),
      },
      {
        path: 'cookie-error',
        name: 'CookieError',
        component: () => import('pages/CookieError.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue'),
  },
]

export default routes
