const tagColors = [
  'red',
  'pink',
  'purple',
  'deep-purple',
  'indigo',
  'blue',
  'light-blue',
  'cyan',
  'teal',
  'green',
  'orange',
  'deep-orange',
  'brown',
  'blue-grey',
]

export function hashCode(str) {
  let hash = 0,
    i,
    chr
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  return (hash * (hash < 0 ? -1 : 1)) % tagColors.length
}

export function getTagColors(tag) {
  return tagColors[hashCode(tag)]
}

export function translateConnectionType(inputType) {
  return inputType === 'UsbScale' ? 'USB (HID)' : 'Serial (COM port)'
}

export function optionList(device) {
  let optionsList = []
  if (device) {
    if (device.device_type.type_name === 'scale') {
      // Scales
      if (device.options.connectionType) {
        optionsList.push({
          label: 'Connection Type',
          value: translateConnectionType(device.options.connectionType),
        })
      }
      if (device.options.port) {
        optionsList.push({
          label: 'Port',
          value: device.options.port,
        })
      }
      if (device.options.baudrate) {
        optionsList.push({
          label: 'Baudrate',
          value: device.options.baudrate,
        })
      }
      if (device.options.printCommand) {
        optionsList.push({
          label: 'Print Command',
          value: device.options.printCommand,
        })
      }
      if (device.isLegacy && device.options.terminatorCode) {
        const legacyTerminators = convertLegacyTerminators(device.options.terminatorCode)
        optionsList.push({
          label: 'Transmit Terminator',
          value: `${legacyTerminators.txTerminator ? legacyTerminators.txTerminator : '-'}`,
        })
        optionsList.push({
          label: 'Receive Terminator',
          value: `${legacyTerminators.rxTerminator ? legacyTerminators.rxTerminator : '-'}`,
        })
      }
      if (device.options.txTerminator) {
        optionsList.push({
          label: 'Transmit Terminator',
          value: `${device.options.txTerminator ? device.options.txTerminator : '-'}`,
        })
      }
      if (device.options.rxTerminator) {
        optionsList.push({
          label: 'Receive Terminator',
          value: `${device.options.rxTerminator ? device.options.rxTerminator : '-'}`,
        })
      }
    } else {
      if (device.options.paperType) {
        optionsList.push({
          label: 'Paper Type',
          value: device.options.paperType,
        })
      }
      if (device.options.color) {
        optionsList.push({
          label: 'Color',
          value: device.options.color,
        })
      } else if (device.options.mono) {
        optionsList.push({
          label: 'Monochrome',
          value: device.options.mono,
        })
      }
      if (device.options.landscape) {
        optionsList.push({
          label: 'Landscape',
          value: device.options.landscape,
        })
      }
      if (device.gsprint_args && device.gsprint_args.length > 0 && device.gsprint_args[0] !== '') {
        optionsList.push({
          label: 'Legacy Options',
          value: device.gsprint_args.join(', '),
        })
      }
      if (device.options.source) {
        optionsList.push({
          label: 'Paper Source',
          value: device.options.source.label,
        })
      }
    }
  }
  return optionsList
}

function convertLegacyTerminators(terminator) {
  let terminatorSplit = terminator.split('-')
  if (terminatorSplit.length > 1) {
    if (terminatorSplit[0] !== '') {
      return {
        txTerminator: terminatorSplit[0],
        rxTerminator: terminatorSplit[1],
      }
    } else {
      return {
        txTerminator: '-',
        rxTerminator: terminatorSplit[1],
      }
    }
  } else {
    return {
      txTerminator: terminator,
      rxTerminator: terminator,
    }
  }
}

export function compare(a, b, comp) {
  let valA = comp ? a[comp] : a
  let valB = comp ? b[comp] : b
  if (!isNaN(valA) && !isNaN(valB)) {
    return valA - valB
  }
  if (!isNaN(Number.parseInt(valA)) && !isNaN(Number.parseInt(valB))) {
    return valA.replace(/^0+/, '') - valB.replace(/^0+/, '')
  }
  return valA.toUpperCase() < valB.toUpperCase() ? -1 : 1
}
