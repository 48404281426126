import Vue from 'vue'
import DeviceTypes from '../assets/device_types'
import VersionManifest from '../assets/client_version_manifest'
import {compare, getTagColors, optionList} from './helpers'
import * as Sentry from '@sentry/vue'
import { Loading } from 'quasar'
import { APP_TYPES } from '../types'

const sessionTestInterval = 600000

let filter = (text, length, clamp) => {
  clamp = clamp || '...'
  let node = document.createElement('div')
  node.innerHTML = text
  let content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp : content
}

Vue.filter('truncate', filter)

Object.defineProperty(Vue.prototype, '$deviceTypes',      {value: DeviceTypes})
Object.defineProperty(Vue.prototype, '$versionManifest',  {value: VersionManifest})
Object.defineProperty(Vue.prototype, '$isAGteB',          { value: isAGteB })
Object.defineProperty(Vue.prototype, '$hasVersionFeature',{ value: hasVersionFeature })
Object.defineProperty(Vue.prototype, '$formatTags',       { value: formatTags })
Object.defineProperty(Vue.prototype, '$tagColor',         { value: getTagColors })
Object.defineProperty(Vue.prototype, '$optionList',       { value: optionList })
Object.defineProperty(Vue.prototype, '$compare',          { value: compare })

export default async ({store, urlPath, router}) => {
  const scope = new Sentry.Scope()
  scope.setTag('location', 'main.js')
  try {
    // Auth disabled - development only
    if (!process.env.USER_AUTH) {
      console.log('User auth disabled')
      const serverUrl = process.env.HUB_SERVER_URL
      if (!serverUrl) {
        const error = new Error('HUB_SERVER_URL must be set when USER_AUTH is disabled')
        setHubConnectionError(error)
        return
      }
      let fakeUser = {
        device_hub_uri: serverUrl,
        user_name: 'Developer+Test',
      }
      await store
        .dispatch('Hub/connect', fakeUser)
        .catch((err) => {
          if (process.env.DEBUGGING) console.trace(err)
        })
    }

    // Auth enabled
    else {
      Loading.show({
        group: 'main-group',
        message: 'Loading Device Hub'
      })

      // re-check session in 10 Minute Intervals (600000 ms)
      setInterval(async () => {
        if (!window.location.href.match(/\/connect/)) {
          startSessionCheck().catch((err) => {
            if (process.env.DEBUGGING) {
              console.trace('Interval session check after ' + sessionTestInterval / 60000 + ' minutes.', err)
              throw {message: err.message, status: err.status}
            }
          })
        }
      }, sessionTestInterval)
      startVisibilitySessionCheck()

      // LOGIN FROM SHIPSTREAM
      if (urlPath.match(/^\/auth#/)) {
        // Attempt to parse an auth token from the url hash
        let params = paramsToObject(urlPath)
        if (process.env.DEBUGGING) console.log('Auth hash params', params)
        // Check if all session parameters are present
        if (
          !params ||
          !(
            params.hasOwnProperty('instance_uid') &&
            params.hasOwnProperty('company_name') &&
            params.hasOwnProperty('logo_uri') &&
            params.hasOwnProperty('wms_uri') &&
            params.hasOwnProperty('device_hub_uri') &&
            params.hasOwnProperty('user_name') &&
            params.hasOwnProperty('user_email') &&
            params.hasOwnProperty('session_expiration') &&
            params.hasOwnProperty('jwt')
          )
        ) {
          const error = new Error('Invalid authentication parameters.')
          setHubConnectionError(error)
          return
        }

        store.dispatch('Hub/setWmsInfo', {
          uri: params.wms_uri,
          company: params.company_name,
        })

        // Logout user if incoming login is not the same as active instance
        const activeInstance = await store.getters['Auth/getActiveInstance']
        if (activeInstance && activeInstance !== params.instance_uid) {
          store.commit('Auth/SET_ACTIVE_INSTANCE', '')
          store.commit('Auth/SET_AUTH_TOKEN', '')
        }

        if (!window.location.href.match(/\/connect/)) {
          await startSessionCheck(params).catch((err) => {
            if (process.env.DEBUGGING) console.log('Verification response', err.message)
            throw {message: err.message, status: err.status}
          })
        }

        // Attempt authenticated Login
        await store.dispatch('Auth/login', params).catch(async (err) => {
          if (process.env.DEBUGGING) console.log('Unable to login', err)
          throw {message: err.message, status: err.status}
        })

        await store.dispatch('Auth/setActiveLogin', params.jwt).catch(async (err) => {
          if (process.env.DEBUGGING) console.log('Error setting active login.', err)
          throw {message: err.message, status: err.status}
        })
      }
      // No parameters given, activate session from local storage, otherwise ignore login attempt
      else if (store.getters['Auth/getAuthToken'] || store.getters['Auth/getActiveInstance']) {
        Loading.show({
          group: 'main-group',
          message: 'Loading Device Hub'
        })
        let user = await store.dispatch('Auth/setActiveLogin', null)
        if (!user) {
          Loading.hide('main-group')
          const error = new Error(
            'No active login available. Please return to ShipStream and log in from `System > Device Hub` again'
          )
          setHubConnectionError(error)
          return
        }

        // Verify Token
        const userVerification = await store.dispatch('Auth/verifySessionToken', user)
        if (userVerification) {
          if (userVerification.success === false) {
            Loading.hide('main-group')
            if (process.env.DEBUGGING) console.log('Verification response', userVerification)
            const error = new Error(
              'Session has expired. Please return to ShipStream and log in from `System > Device Hub` again'
            )
            setHubConnectionError(error)
            return
          }
        } else {
          Loading.hide('main-group')
          if (process.env.DEBUGGING) console.log('No user verification response')
          const error = new Error(
            'Could not verify session. Please return to ShipStream and log in from `System > Device Hub` again'
          )
          setHubConnectionError(error)
          return
        }

        // Verify Session
        await startSessionCheck(user).catch((err) => {
          if (process.env.DEBUGGING) console.log('Verification response', err.message)
          throw {message: err.message, status: err.status}
        })
        Loading.show({
          group: 'main-group',
          message: 'Authenticating User'
        })
        await store
          .dispatch('Auth/switchUser', user)
          .then((res) => {
            if (res.success === false) {
              if (process.env.DEBUGGING) console.trace('Unable to switch user', res)
              throw { message: 'Failed to switch user.', status: res.status }
            }
          })
          .catch((err) => {
            if (process.env.DEBUGGING) console.trace('Error occurred while switching user.', err)
            throw { message: err.message, status: err.status }
          })
      }
      // No auth methods available
      else {
        Loading.hide('main-group')
        store.commit('Hub/SET_HUB_CONNECTION', {
          connected: false,
          message: 'Connection is not authenticated.',
          code: '',
        })
        store.commit('Auth/SET_AUTH_TOKEN', '')
        if (!window.location.href.match(/\/connect/) && !window.location.href.match(/\/downloads/)) {
          router.push({path: '/connect'})
            .catch(() => {})
        }
      }

      function startVisibilitySessionCheck() {
        document.addEventListener('visibilitychange', function() {
          if (document.visibilityState === 'visible') {
            startSessionCheck()
              .then((res) => {
                if (process.env.DEBUGGING) console.log('Visibility check: ', res.success)
              })
              .catch((err) => {
                if (process.env.DEBUGGING) console.log('Visibility check: ', err)
                setHubConnectionError(err)
              })
          }
        })
      }

      async function startSessionCheck(user) {
        return new Promise((resolve, reject) => {
          store
            .dispatch('Auth/verifySession', user)
            .then((res) => {
              if (res.success === true) {
                resolve(res)
              } else {
                reject(res)
              }
            })
            .catch((err) => {
              if (process.env.DEBUGGING) console.trace('Session Verification failed.', err)
              reject(err)
            })
        })
      }
    }
  } catch (error) {
    setHubConnectionError(error)
  }

  /**
   * Set the Hub Connection to false, set an error message and unset any authentication.
   * @param error
   */
  function setHubConnectionError(error) {
    if (process.env.DEBUGGING) console.trace('Setting Hub Connection Error: ', error)
    Sentry.captureException(error)
    const errorMsg =
      error && error.message ? error.message : 'An error occurred while trying to connect to Device Hub Server.'
    const errorCode = error.status
    store.commit('Hub/SET_HUB_CONNECTION', {
      connected: false,
      message: errorMsg,
      code: errorCode,
    })
    store.commit('Auth/SET_AUTH_TOKEN', '')
    if (!window.location.href.match(/\/connect/) && !window.location.href.match(/\/downloads/)) {
      router.push({path: '/connect'}).catch(() => {})
    }
  }
}

// Parse URI hash parameters into JSObject
function paramsToObject(urlPath) {
  let url = new URL(urlPath, 'http://example.com')
  let hash = url.hash.substring(1)
  if (hash === '') {
    return null
  }
  let params = hash.split(';')
  return Object.fromEntries(
    params.map((param) => {
      let [key, value] = param.split('=', 2)
      return [key, decodeURIComponent(value)]
    })
  )
}

function isAGteB(a, b) {
  return a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'}) >= 0
}

export function formatTags(tags) {
  const newTags = new Set()
  tags.forEach(tag => {
    newTags.add(tag.toLowerCase())
  })
  return [...newTags]
}

function hasVersionFeature(featureVersion, client) {
  if (process.env.NODE_ENV === 'development') {
    return true
  }
  if (featureVersion.da && client.appType === APP_TYPES.DeviceApp) {
    return isAGteB(client.version, featureVersion.da)
  }
  else if (featureVersion.ds && client.appType === APP_TYPES.DeviceService) {
    return isAGteB(client.version, featureVersion.ds)
  }
  return false
}
