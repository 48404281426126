import {io} from 'socket.io-client'
import { formatTags } from 'boot/main'
import {Loading, Notify} from 'quasar'
import { APP_TYPES, Client, Device } from './types'

/**
 * @class
 * @property {Socket} socket
 */
class HubFrontendClient {
  constructor() {
    this.socket = null
  }

  disconnect({commit}) {
    commit('SET_CONNECTED_CLIENTS', {})
    commit('SET_HUB_CONNECTION', {
      connected: false,
      message: 'Disconnected',
    })
    if (this.socket) {
      this.socket.off()
      this.socket.close()
    }
    this.socket = null
  }

  async connect(params, {commit, getters, dispatch}) {
    if (process.env.DEBUGGING) console.log('CONNECTING...')
    commit('SET_CONNECTED_CLIENTS', {})
    this.disconnect({commit})
    let url = new URL(params.device_hub_uri)
    url.pathname = '' // Avoid 'Invalid namespace' error
    this.socket = await io(url.toString(), {
      path: '/devices/frontend.io/',
      transports: ['websocket'],
      auth: {
        token: params.jwt,
      },
    })
    return new Promise((resolve, reject) => {
      try {
        // Socket IO event handlers
        this.socket.io.on('error', (err) => {
          Loading.show()
          if (process.env.DEBUGGING) console.log('SOCKET ERROR', err)
          commit('SET_HUB_CONNECTION', {
            connected: false,
            message: 'Connection error. Attempting to reconnect.',
          })
        })

        this.socket.io.on('reconnect_error', (err) => {
          if (err) {
            if (process.env.DEBUGGING) console.log('SOCKET RE-CONNECT ERROR', err)
            commit('SET_CONNECTED_CLIENTS', {})
            commit('SET_HUB_CONNECTION', {
              connected: false,
              message: 'Connection error. Attempting to reconnect.',
            })
          }
        })

        this.socket.io.on('reconnect', (attempt) => {
          commit('SET_HUB_CONNECTION', {
            connected: true,
            message: 'Reconnected',
          })
          commit('Auth/SET_AUTH_TOKEN', params.jwt, {root: true})
          console.log(`Successful Reconnection attempt: ${attempt}`)
        })

        this.socket.io.on('reconnect_attempt', (attempt) => {
          commit('Auth/SET_AUTH_TOKEN', params.jwt, {root: true})
          console.log(`Reconnection attempt ${attempt}...`)
        })

        this.socket.io.on('reconnect_failed', () => {
          if (process.env.DEBUGGING) console.log('SOCKET RE-CONNECT FAILED')
          console.log(`Reconnection attempt failed`)
        })

        this.socket.on('connect_error', (err) => {
          Loading.show()
          commit('SET_CONNECTED_CLIENTS', {})
          if (err) {
            console.log('SOCKET CONNECT ERROR', err.message)
            commit('SET_HUB_CONNECTION', {
              connected: false,
              message: 'Connection error. Attempting to reconnect.',
            })
          }
        })

        this.socket.on('setTotalScanTime', (totalTime) => {
          dispatch('startScanTimer', totalTime + 2)
        })

        this.socket.on('disconnect', () => {
          Loading.show()
          console.log('SOCKET DISCONNECT')
          commit('SET_CONNECTED_CLIENTS', {})
          commit('SET_HUB_CONNECTION', {
            connected: false,
            message: 'Disconnected',
          })
        })

        this.socket.io.on('close', () => {
          console.log('SOCKET CLOSED')
          commit('SET_CONNECTED_CLIENTS', {})
          commit('SET_HUB_CONNECTION', {
            connected: false,
            message: 'Disconnected',
          })
        })

        this.socket.io.on('open', () => {
          console.log('SOCKET OPENED')
        })

        this.socket.on('clientDisconnected', (socketId) => {
          dispatch('refreshTagOptions')
          commit('UPDATE_CLIENT', { socketId })
        })

        this.socket.on('clientConnected', (payload, metadata) => {
          const {client, socketId} = payload
          if (metadata) {
            const {migrated, message} = metadata
            if (migrated) {
              Notify.create({
                color: 'primary',
                icon: 'info',
                message: message,
              })
            }
          }

          const newClient = this.hydrateClient(client)
          dispatch('refreshTagOptions')
          commit('UPDATE_CLIENT', { client: newClient, socketId: socketId })
        })

        this.socket.on('modifyClient', ({ client, socketId }) => {
          client = this.hydrateClient(client)
          dispatch('refreshTagOptions')
          commit('UPDATE_CLIENT', { client, socketId })
        })

        this.socket.on('downloadTestProgress', (progress) => {
          commit('SET_DOWNLOAD_TEST_PROGRESS', progress)
        })

        this.socket.on('listSize', (listSize) => {
          this.serverClientListSize = listSize
          if (listSize) {
            Loading.show({
              group: 'main-group',
              message: `Loading ${listSize} Clients...`
            })
          } else {
            Loading.hide()
          }
        })

        this.socket.on('refreshClientList', (clientsObject, metadata) => {
          if (metadata) {
            const { migrated, message } = metadata
            if (migrated) {
              Notify.create({
                color: 'primary',
                icon: 'info',
                message: message,
              })
            }
          }
          const newClientList = Object.values(clientsObject).map((clientData) => {
            return this.hydrateClient(clientData)
          })

          const machineFilter = getters.getMachineFilter
          if (machineFilter && !clientsObject[machineFilter.socketId]) {
            commit('SET_MACHINE_FILTER', {})
          }
          dispatch('refreshTagOptions')
          commit('REFRESH_CLIENTS', newClientList)
          Loading.hide()
        })

        this.socket.on('downloadProgress', (socketId, progress) => {
          commit('SET_DOWNLOAD_PROGRESS', { progress, socketId })
        })

        this.socket.on('downloadCancelled', (socketId) => {
          commit('SET_DOWNLOAD_PROGRESS', { progress: undefined, socketId })
        })

        this.socket.on('serverUpdateStatus', (payload) => {
          if (payload && payload.success) {
            Notify.create({
              color: 'primary',
              icon: 'upgrade',
              message: payload.message,
            })
            return
          } else {
            if (payload && payload.message) {
              Notify.create({
                color: 'negative',
                icon: 'upgrade',
                message: payload.message,
              })
              return
            }
          }
          Notify.create({
            color: 'negative',
            icon: 'upgrade',
            message: 'Unable to complete the update.',
          })
        })

        this.socket.on('updateLogs', (log) => {
          dispatch('updateLogs', log)
        })

        this.socket.on('setAvailableScales', (scales) => {
          commit('SET_AVAILABLE_SCALES', scales)
        })

        this.socket.on('setReconnection', (clientId) => {
          commit('SET_RECONNECTED_CLIENT', { clientId, time: Date.now() })
        })

        this.socket.on('connect', () => {
          if (process.env.DEBUGGING) console.log('Connection successful')
          commit('SET_CONNECTED_CLIENTS', {})
          dispatch('loadClients')
          commit('Auth/SET_AUTH_TOKEN', params.jwt, {root: true})
          resolve({success: true, message: 'Connection successful'})
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  emitWithTimeout(event, ...data) {
    return new Promise((resolve, reject) => {
      this.socket.emit(event, ...data, this.withTimeout(resolve, reject))
    })
  }

  emitWithSpecifiedTimeout(duration, event, ...data) {
    return new Promise((resolve, reject) => {
      this.socket.emit(event, ...data, this.withTimeout(resolve, reject, duration))
    })
  }

  // takes a success callback and a timeout callback and returns a promise
  withTimeout(onSuccess, onTimeout, duration) {
    let called = false
    const timer = setTimeout(() => {
      if (called) {
        return
      }
      called = true
      if (onTimeout) {
        onTimeout({timeout: true})
      }
    }, duration || 20000)
    return (...args) => {
      if (called) {
        return
      }
      called = true
      clearTimeout(timer)
      onSuccess(...args)
    }
  }

  /**
   * Convert the raw client data from Hub Server into a Client object
   * @param {Object} clientData
   * @returns {Client}
   */
  hydrateClient(clientData) {
    const clientDevices = []
    for (const deviceData of clientData.devices || []) {
      const device = Object.assign(new Device(), deviceData)
      device.socketId = clientData.socketId
      device.tagsSelected = formatTags(device.tagsSelected)
      clientDevices.push(device)
    }
    delete clientData.devices
    const client = Object.assign(new Client(), clientData)
    if (!client.label) {
      client.label = `${clientData.user}@${clientData.machine}`
    }
    client.devices = clientDevices
    if (client.appType === APP_TYPES.Legacy) {
      Object.freeze(client.devices)
      Object.freeze(client)
    }
    return client
  }
}

export default HubFrontendClient
