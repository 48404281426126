import axios from 'axios'
import {Loading} from 'quasar'

let authToken = localStorage.getItem('_auth_jwt') || ''
let activeInstance = localStorage.getItem('active_instance') || ''
let logins = JSON.parse(localStorage.getItem('logins') || '{}')

// State
const state = {
  authToken,
  activeInstance,
  logins,
}
if (process.env.DEBUGGING && activeInstance) console.log('Loaded active instance from local storage', state)

// Getters
const getters = {
  getAuthToken: (state) => {
    return state.authToken
  },
  getActiveInstance: (state) => {
    return state.activeInstance
  },
  getLogins: (state) => {
    return state.logins
  },
  getActiveLogin: (state) => {
    if (state.activeInstance && state.logins[state.activeInstance]) {
      return state.logins[state.activeInstance]
    }
    return null
  },
}

// Actions
const actions = {
  async setActiveLogin({state, commit}, jwt) {
    const instance = state.activeInstance
    const token = state.authToken
    const logins = state.logins
    return new Promise((resolve, reject) => {
      try {
        let checkToken = null
        if (jwt) {
          checkToken = jwt
        } else if (token) {
          checkToken = token
        } else if (instance && Object.values(logins).length > 0) {
          checkToken = logins[instance].jwt
        }
        let returnLogin = null
        if (checkToken) {
          Object.values(logins).forEach((login) => {
            if (login.jwt === checkToken) {
              returnLogin = login
            }
          })
          if (returnLogin) {
            commit('SET_ACTIVE_INSTANCE', returnLogin.instance_uid)
            resolve(returnLogin)
          } else {
            reject('No active login available.')
          }
        } else {
          reject('No active session available.')
        }
      } catch (error) {
        reject('Unable to get active login.')
      }
    })
  },

  /**
   * Validate token on Device Hub
   * @typedef {Object} TokenVerification
   * @property {boolean} success - Verification is successful
   * @property {string} message - Verification message
   * @returns {Promise<TokenVerification>}
   */
  async verifySessionToken({commit, dispatch, getters}, user) {
    return await new Promise((resolve, reject) => {
      if (!user) {
        user = getters.getActiveLogin
      }
      // Check session expiration locally before attempting the session verification
      if (user && sessionExpired(user.session_expiration)) {
        resolve({success: false, message: 'Session has expired.'})
        return
      }
      axios
        .post(user.device_hub_uri + '/verifySessionToken', null, {
          headers: {
            Authorization: 'Bearer ' + user.jwt,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async verifySession({state, commit, dispatch}, user) {
    function parseJwt(token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )

      return JSON.parse(jsonPayload)
    }

    return await new Promise((resolve, reject) => {
      if (!user) {
        user = state.logins[state.activeInstance]
      }
      if (!user) {
        reject({
          success: false,
          message:
            'No user session available for authentication. Try logging in to ShipStream, then go to System > Device Hub again',
        })
      }
      const jwtData = parseJwt(user.jwt)
      const userId = jwtData.user_id
      if (!user.wms_uri || !userId) {
        reject({
          success: false,
          message: 'Insufficient session data. Try logging in to ShipStream, then go to System > Device Hub again',
        })
      }
      // Check session expiration locally before attempting the session verification
      if (user && sessionExpired(user.session_expiration)) {
        reject({
          success: false,
          message: 'Session has expired.',
        })
        return
      }
      axios
        .get(`${user.wms_uri}hub/revalidate?user_id=${encodeURIComponent(userId)}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res && res.status === 204) {
            resolve({success: true})
          } else {
            reject({
              success: false,
              status: res.status ? res.status : null,
              message:
                'User is not authenticated on ShipStream. Try logging in to ShipStream, then go to System > Device Hub again',
            })
            if (state.activeInstance) {
              commit('UNSET_LOGIN', user)
            }
          }
        })
        .catch((err) => {
          //Clear jwt for failed session
          let newLogins = Object.assign({}, state.logins)
          if (Object.values(newLogins).length > 0) {
            newLogins[user.instance_uid].jwt = ''
            commit('SET_LOGINS', newLogins)
            if (state.activeInstance) {
              commit('UNSET_LOGIN', user)
            }
          }
          if (!err?.response?.status) {
            reject({
              success: false,
              status: null,
              message: 'No status code returned during revalidation.',
            })
            return
          }
          const revalidateResponse = err?.response?.status
          if (!revalidateResponse) {
            reject({
              success: false,
              status: 404,
              message: 'No response from ShipStream while attempting to revalidate user session.',
              reason: err.toString(),
            })
          }
          switch (revalidateResponse) {
            case 400:
              reject({
                success: false,
                status: 400,
                message:
                  'Cookies are disabled on your browser. Please disable any cookie-blocking extensions and login again.',
                reason: err.message,
              })
              break
            case 403:
              reject({
                success: false,
                status: 403,
                message:
                  'User is not authenticated on ShipStream. Try logging in to ShipStream, then go to System > Device Hub again',
                reason: err.message,
              })
              break
            case 404:
              reject({
                success: false,
                status: 404,
                message: 'Could not authenticate user on ShipStream. Please try again later.',
                reason: err.message,
              })
              break
            default:
              reject({
                success: false,
                status: err.response.status,
                message: err.response.statusText,
                reason: err.message,
              })
          }
        })
    })
  },
  login({state, commit, dispatch}, user) {
    return new Promise((resolve, reject) => {
      axios.defaults.headers.post['Authorization'] = `Bearer ${user.jwt}`
      axios
        .post(user.device_hub_uri + '/validate', user)
        .then((res) => {
          Loading.show({
            group: 'main-group',
            message: 'Authenticating User'
          })
          commit('ADD_LOGIN', user)
          if (res && res.status === 200) {
            dispatch('Hub/connect', user, {root: true})
              .then((res) => {
                Loading.hide('main-group')
                resolve(res)
              })
              .catch((err) => {
                if (process.env.DEBUGGING) console.trace('Error connecting user', err)
                resolve(err)
              })
          } else {
            if (process.env.DEBUGGING) console.trace('Device Hub /validate error ', res)
            reject(res)
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              if (process.env.DEBUGGING) console.trace('Validation Error. ', error)
              reject(new Error('No response from URI.'))
            }
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  },
  logout({commit, dispatch}, user) {
    commit('UNSET_LOGIN', user)
  },
  async switchUser({state, commit, dispatch}, user) {
    if (user && user.instance_uid) {
      return await new Promise((resolve, reject) => {
        dispatch('Hub/switchUser', user, {root: true})
          .then((res) => {
            commit('SET_ACTIVE_INSTANCE', user.instance_uid)
            commit('SET_AUTH_TOKEN', user.jwt)
            resolve(res)
          })
          .catch((err) => {
            if (process.env.DEBUGGING) console.log('Hub/switchUser Error', err)
            reject(err)
          })
      })
    } else {
      return {success: false, message: 'No UID present'}
    }
  },
  reset({commit, dispatch}) {
    commit('SET_AUTH_TOKEN', '')
    commit('SET_ACTIVE_INSTANCE', '')
    commit('SET_LOGINS', {})
    dispatch('Hub/disconnect', null, {root: true})
  },
}

// Mutations
const mutations = {
  SET_AUTH_TOKEN: (state, value) => {
    state.authToken = value || null
    localStorage.setItem('_auth_jwt', value || '')
  },
  SET_ACTIVE_INSTANCE: (state, value) => {
    state.activeInstance = value
    localStorage.setItem('active_instance', value || '')
  },
  SET_LOGINS: (state, value) => {
    state.logins = value
    localStorage.setItem('logins', JSON.stringify(state.logins))
  },
  ADD_LOGIN: (state, user) => {
    state.logins[user.instance_uid] = user
    localStorage.setItem('logins', JSON.stringify(state.logins))
  },
  UNSET_LOGIN: (state, user) => {
    if (user.instance_uid === state.activeInstance) {
      state.activeInstance = ''
      state.authToken = null
      localStorage.setItem('active_instance', '')
      localStorage.setItem('_auth_jwt', '')
    }
    delete state.logins[user]
    localStorage.setItem('logins', JSON.stringify(state.logins))
  },
}

function sessionExpired(expiration) {
  return Math.round(Date.now() / 1000) > parseInt(expiration)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
