import HubFrontendClient from '../../hub-frontend-client'
import { APP_TYPES, Client } from '../../types'
import BarcodeComponents from '../../assets/barcode_components'
import axios from 'axios'
import Vue from 'vue'
import { extend } from 'quasar'

const hubFrontendClient = new HubFrontendClient()
const maxLogs = 1000
const defaultTagChoices = ['device app', 'device service', 'legacy', 'needs upgrade', 'conflict']

// State
const state = {
  hubServerUrl: null,
  hubConnection: {
    connected: false,
    code: null,
    message: 'Not Connected.',
  },
  /** @type {Object<string, Client>} */
  connectedClients: {},
  allPrintersByClient: {},
  availableScales: {},
  /** @type {Client} */
  conflictCount: 1,
  isLoading: false,
  logs: [],
  newLogCount: 0,
  errorLogCount: 0,
  jobIsProcessing: false,
  deviceAppInfo: {},
  deviceServiceInfo: {},
  hasClients: true,
  wmsInfo: null,
  scanTimePassed: 0,
  downloadProgress: {},
  isDownloading: {},
  search: null,
  sort: 'uniqueId',
  tagFilters: [],
  tagFiltersNegate: false,
  typeFilter: null,
  typeFilterNegate: false,
  machineFilter: {},
  allTagOptions: [],
  eventLogSearch: null,
  eventLogSeverityFilters: [],
  eventLogSeverityFiltersNegate: false,
  eventLogMachineFilter: null,
  eventLogMachineFilterNegate: false,
  eventLogDeviceTypeFilter: null,
  eventLogDeviceTypeFilterNegate: false,
  showEventLogFilters: false,
  isEventLogDescending: true,
  eventLogPaused: false,
  selectedClient: null,
  staticLogList: [],
  targetAppVersion: {},
  targetServiceVersion: {},
  downloadTestProgress: null,
  reconnections: {}
}
const cleanState = Object.assign({}, state)

// Getters
const getters = {
  getDownloadTestProgress: (state) => {
    return state.downloadTestProgress
  },
  getReconnections: (state) => {
    return state.reconnections
  },
  getIsEventLogPaused: (state) => {
    return state.eventLogPaused
  },
  getDownloadProgress: (state) => {
    return state.downloadProgress
  },
  getIsDownloading: (state) => {
    return state.isDownloading
  },
  getScanTimePassed: (state) => {
    return state.scanTimePassed
  },
  getWmsInfo: (state) => {
    return state.wmsInfo
  },
  getHasClients: (state) => {
    return state.hasClients
  },
  getCombinedClientList: (state) => {
    const newObj = extend(true, {}, {...state.connectedClients})
    return Object.values(newObj).reduce((acc, obj) => {
      if (obj.isLegacy) {
        let key = obj.machine
        if (!acc[key]) {
          Vue.set(acc, key, obj)
        } else {
          const newObject = Object.assign(new Client(), acc[key])
          newObject.devices = [...acc[key].devices, ...obj.devices]
          Vue.set(acc, key,  newObject)
        }
      } else {
        Vue.set(acc, obj.socketId, obj)
      }
      return acc
    }, {})
  },
  getDeviceServiceInfo: (state) => {
    return state.deviceServiceInfo
  },
  getDeviceAppInfo: (state) => {
    return state.deviceAppInfo
  },
  getSearch: (state) => {
    return state.search
  },
  getTagFilters: (state) => {
    return state.tagFilters
  },
  getTypeFilter: (state) => {
    return state.typeFilter
  },
  getJobIsProcessing(state) {
    return state.jobIsProcessing
  },
  getAvailableScales(state) {
    return state.availableScales
  },
  getAllLogs: (state) => {
    return state.logs
  },
  getErrorLogCount: (state) => {
    return state.errorLogCount
  },
  isLoading: (state) => {
    return state.isLoading
  },
  getHubServerUrl: (state) => {
    return state.hubServerUrl
  },
  getHubConnection: (state) => {
    return state.hubConnection
  },
  getMachineFilter: (state) => {
    return state.machineFilter
  },
  getAllTagOptions: (state) => {
    return [...state.allTagOptions, ...defaultTagChoices]
  },
  getFiltersEnabled: (state) => {
    return state.typeFilter || state.tagFilters.length > 0 || state.search
  },
  getShowEventLogFilters: (state) => {
    return state.showEventLogFilters
  },
  isUniqueDeviceId: (state, getters) => (deviceId) => {
    return !getters.getDeviceIds.includes(deviceId) && !getters.getDeviceIds.includes('legacy_' + deviceId)
  },
  getConnectedClients: (state) => {
    return state.connectedClients
  },
  getSelectedClient: (state) => {
    return state.selectedClient
  },
  getAllPrintersByClient: (state) => {
    return state.allPrintersByClient
  },
  devicesSorted: (state, getters) => {
    let devicesSorted = {}
    let keys = []
    if (Object.values(state.machineFilter).length > 0) {
      state.machineFilter.devices.forEach(device => {
        keys.push(device.socketId + '/' + device.id)
      })
    } else {
      keys = Object.keys(getters.getAllDevices)
    }
    let keysOrdered = keys
      .sort((a, b) => {
        let deviceAProp = getters.getAllDevices[a][state.sort].toLowerCase()
        let deviceBProp = getters.getAllDevices[b][state.sort].toLowerCase()
        if (deviceAProp > deviceBProp) return 1
        else if (deviceAProp < deviceBProp) return -1
        else return 0
      })
    keysOrdered.forEach((key) => {
      devicesSorted[key] = getters.getAllDevices[key]
    })
    return devicesSorted
  },
  devicesFiltered: (state, getters) => {
    function getClientByDeviceId (id) {
      return Object.values(state.connectedClients).find(client => client.devices.find(device => device.id === id) )
    }
    let devicesSorted = Object.values(getters.devicesSorted)
    if (state.tagFilters && state.tagFilters.length > 0) {
      devicesSorted = devicesSorted.filter((x) => {
        if (x.tagsSelected && state.tagFilters) {
          if (state.tagFiltersNegate) {
            return state.tagFilters.every((o) => {
              if (defaultTagChoices.some(item => state.tagFilters.includes(item))) {
                const deviceClient = getClientByDeviceId(x.id)
                if (state.tagFilters.includes('legacy')) {
                  return deviceClient.appType !== APP_TYPES.Legacy
                }
                if (state.tagFilters.includes('device service')) {
                  return deviceClient.appType !== APP_TYPES.DeviceService
                }
                if (state.tagFilters.includes('device app')) {
                  return deviceClient.appType !== APP_TYPES.DeviceApp
                }
                if (state.tagFilters.includes('needs upgrade')) {
                  if (deviceClient.appType === APP_TYPES.DeviceApp) {
                    return deviceClient.version === state.deviceAppInfo.version
                  } else if (deviceClient.appType === APP_TYPES.DeviceService) {
                    return deviceClient.version === state.deviceServiceInfo.version
                  }
                }
                if (state.tagFilters.includes('conflict')) {
                  const matchingDevices = Object.values(getters.getAllDevices).filter(
                    device => device.id.replace('legacy_', '') === x.id.replace('legacy_', '')
                  )
                  return !(matchingDevices.length > 1 && matchingDevices.some(device => device.socketId !== deviceClient.socketId))
                }
              }
              return !x.tagsSelected.includes(o)
            })
          } else {
            // If user selected a default tag...
            if (defaultTagChoices.some(item => state.tagFilters.includes(item))) {
              const deviceClient = getClientByDeviceId(x.id)
              if (state.tagFilters.includes('legacy')) {
                return deviceClient.appType === APP_TYPES.Legacy
              }
              if (state.tagFilters.includes('device service')) {
                return deviceClient.appType === APP_TYPES.DeviceService
              }
              if (state.tagFilters.includes('device app')) {
                return deviceClient.appType === APP_TYPES.DeviceApp
              }
              if (state.tagFilters.includes('needs upgrade')) {
                if (deviceClient.appType === APP_TYPES.DeviceApp) {
                  return deviceClient.version !== state.deviceAppInfo.version
                } else if (deviceClient.appType === APP_TYPES.DeviceService) {
                  return deviceClient.version !== state.deviceServiceInfo.version
                }
              }
              if (state.tagFilters.includes('conflict')) {
                const matchingDevices = Object.values(getters.getAllDevices).filter(
                  device => device.id.replace('legacy_', '') === x.id.replace('legacy_', '')
                )
                return matchingDevices.length > 1 && matchingDevices.some(device => device.socketId !== deviceClient.socketId);
              }
            }
            return state.tagFilters.some((o) => {
              return x.tagsSelected.includes(o)
            })
          }
        } else {
          return null
        }
      })
    }
    if (state.typeFilter) {
      devicesSorted = devicesSorted.filter((x) => {
        return state.typeFilterNegate ? x.device_type.type_name !== state.typeFilter.value :
          x.device_type.type_name === state.typeFilter.value
      })
    }
    if (state.search !== '' && state.search !== null) {
      devicesSorted = devicesSorted.filter((device) => {
        let deviceIdLowerCase = device.uniqueId?.toLowerCase()
        let deviceNameLowerCase = device.device_name?.toLowerCase()
        let searchLowerCase = state.search?.toLowerCase()
        return deviceNameLowerCase?.includes(searchLowerCase) || deviceIdLowerCase?.includes(searchLowerCase)
      })
    }
    return devicesSorted
  },
  getDevices: (state, getters) => {
    return getters.devicesFiltered.reduce((result, device) => {
      if (device.id.includes('legacy_')) {
        result[(device.machine || device.socketId) + '/' + device.id] = device
      } else {
        result[device.socketId + '/' + device.id] = device
      }
      return result
    }, {})
  },
  getDeviceIds: (state) => {
    return Object.values(state.connectedClients).reduce((acc, client) => {
      client.devices.forEach(device => {
        acc.push(device.id)
      })
      return acc
    }, [])
  },
  getAllDevices: (state) => {
    return Object.keys(state.connectedClients).reduce((result, socketId) => {
      state.connectedClients[socketId]?.devices.forEach(device => {
        result[socketId + '/' + device.id] = device
      })
      return result
    }, {})
  },
  getIsEventLogDescending: (state) => {
    return state.isEventLogDescending
  },
  logsSorted: (state) => {
    let logs
    if (state.eventLogPaused) {
      logs = state.staticLogList
    } else {
      logs = state.logs
    }
    if (state.isEventLogDescending) {
      const newLogs = logs.slice()
      return newLogs.reverse()
    }
    return logs
  },
  logsFiltered: (state, getters) => {
    let sortedLogs = getters.logsSorted
    if (state.showEventLogFilters) {
      if (state.eventLogMachineFilter && Object.values(state.eventLogMachineFilter).length > 0) {
        sortedLogs = sortedLogs.filter((x) => {
          if (x.client && x.client.label) {
            return state.eventLogMachineFilterNegate ? !x.client?.label?.includes(state.eventLogMachineFilter.label)  :
              x.client?.label?.includes(state.eventLogMachineFilter?.label)
          } else {
            return null
          }
        })
      }
      if (state.eventLogSeverityFilters && state.eventLogSeverityFilters.length > 0) {
        sortedLogs = sortedLogs.filter((x) => {
          if (x.type && state.eventLogSeverityFilters) {
            if (state.eventLogSeverityFiltersNegate) {
              return state.eventLogSeverityFilters.every((o) => {
                return x.type !== o.value
              })
            } else {
              return state.eventLogSeverityFilters.some((o) => {
                return x.type === o.value
              })
            }
          } else {
            return null
          }
        })
      }
      if (state.eventLogDeviceTypeFilter && state.eventLogDeviceTypeFilter.value) {
        sortedLogs = sortedLogs.filter((x) => {
          return state.eventLogDeviceTypeFilterNegate ?
            state.eventLogDeviceTypeFilter?.value !== x.device?.device_type.type_name :
            state.eventLogDeviceTypeFilter?.value === x.device?.device_type.type_name
        })
      }
    }
    if (state.eventLogSearch) {
      const searchLowerCase = state.eventLogSearch?.toLowerCase()
      return sortedLogs.filter((log) => {
        let device = log.device
        let description = log.description?.join(' ')
        return (
          (device &&
            (device.device_name?.toLowerCase().includes(searchLowerCase) ||
              device.uniqueId?.toLowerCase().includes(searchLowerCase) ||
              device.socketId?.toLowerCase().includes(searchLowerCase))) ||
          (description &&
            description.length > 0 &&
            description.toLowerCase().includes(searchLowerCase))
        )
      })
    }
    return sortedLogs
  },
  getLogs: (state, getters) => {
    return getters.logsFiltered
  },
  getLogDeviceTypeFilter: (state) => {
    return state.eventLogDeviceTypeFilter
  },
  getSelectedLogSeverity: (state) => {
    return state.eventLogSeverityFilters
  },
  getEventLogMachineFilter: (state) => {
    return state.eventLogMachineFilter
  },
}

// Actions
const actions = {
  getAvailablePorts ({commit}, clientSocketId) {
    return hubFrontendClient.emitWithTimeout('getAvailablePorts', clientSocketId)
  },
  async generateBarcodeLabel({commit, state, rootState}, data) {
    const activeLogin = rootState.Auth.logins[rootState.Auth.activeInstance]
    let zplData
    switch (data.barcodeType.value) {
      case '128b':
        zplData = generate128BZpl(data, activeLogin)
        break
      case 'dm':
        zplData = generateDataMatrixZpl(data, activeLogin)
        break
      default:
        zplData.success = false
        zplData.error = 'ERROR: Invalid barcode type.'
        return zplData
    }
    const labelApiBaseUrl = (process.env.NODE_ENV === 'development' ? 'http://' : 'https://') + 'api.labelary.com/v1'
    const requestUrl = `${labelApiBaseUrl}/printers/8dpmm/labels/${getInches(
      data.size.labelWidth,
      data.size.unit
    )}x${getInches(data.size.labelHeight, data.size.unit)}/0/`
    console.log(zplData)
    zplData.image = await getBarcodeImage(requestUrl, zplData.data)
    if (!zplData.image) {
      zplData.success = false
      zplData.error = 'ERROR: There was a problem requesting the label image.'
    }
    return zplData
  },
  async sendTestFile({commit, state, rootState}, formData) {
    return await new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.hubServerUrl + '/sendPrintJob',
        data: formData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + rootState.Auth.authToken,
        },
      })
        .then((response) => {
          if (process.env.DEBUGGING) console.log('sendTestFile', response)
          resolve(response)
        })
        .catch((error) => {
          if (process.env.DEBUGGING) console.log('sendTestFile error', error)
          if (error && error.response) {
            resolve(error.response)
          } else {
            reject(error)
          }
        })
    })
  },
  // sendHealthPing ({ commit, state }, client) { // TODO Health Ping
  //   return new Promise((resolve, reject) => {
  //     hubFrontendClient
  //       .emitWithTimeout('ping', client.socketId)
  //       .then(res => {
  //         resolve(res)
  //       })
  //       .catch(e => {
  //         reject(e)
  //       })
  //   })
  // },
  stopNetworkTests  ({commit}, client) {
    return new Promise((resolve, reject) => {
      hubFrontendClient
        .emitWithTimeout('stopNetworkTests', client.socketId)
        .then(res => {
          resolve(res)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  startNetworkTool ({commit}, { client, networkTool, socketTimeout }) {
    return new Promise((resolve, reject) => {
      hubFrontendClient
        .emitWithSpecifiedTimeout(socketTimeout, 'startNetworkTool', { socketId: client.socketId, networkTool, socketTimeout})
        .then(res => {
          resolve(res)
        })
        .catch(e => {
          console.log(e)
          const response = {
            success: false,
            data: JSON.stringify(e),
            message: e.timeout ? 'Websocket Timed out' : e.message ? e.message : `Failed to run ${networkTool} on ${client.label}`
          }
          resolve(response)
        })
    })
  },
  confirmDnsResponse ({commit}, client) {
    return new Promise((resolve, reject) => {
      hubFrontendClient
        .emitWithTimeout('confirmDnsResponse', client.socketId)
        .then(res => {
          resolve(res)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  loadClientPrinterInfo({commit, state, getters}, client) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_LOADING_PRINTER_INFO', {client, value: true})
      hubFrontendClient
        .emitWithTimeout('loadClientPrinterInfo', client.socketId)
        .then((res) => {
          if (res.success) {
            commit('SET_PRINTER_INFO', {client, value: res.data})
            resolve(res.data)
          } else {
            if (res.detail) {
              console.log('loadClientPrinterInfo', res)
            }
            reject(res.message)
          }
        })
        .catch((error) => {
          console.log('loadClientPrinterInfo', error)
          reject(error)
        })
        .finally(() => {
          commit('SET_IS_LOADING_PRINTER_INFO', {client, value: false})
        })
    })
  },
  loadLatestDeviceAppInfo({commit}) {
    return new Promise((resolve, reject) => {
      const deviceAppInfoUrl = `${process.env.DEVICE_APP_DOWNLOAD_BASE_URL}${
        process.env.DOWNLOADS_CHANNEL === 'dev' ? 'dev.yml' : 'stable.yml'
      }?&timestamp=${new Date().getTime()}`
      console.log(deviceAppInfoUrl)
      axios
        .get(deviceAppInfoUrl, {})
        .then((res) => {
          if (res.status && res.status === 200) {
            let version = getVersionFromYaml(res.data)
            let filename = getFileNameFromYaml(res.data)
            let releaseDate = getReleaseDateFromYaml(res.data)
            releaseDate = new Date(releaseDate)
            let deviceAppInfo = {
              version: version,
              filename: filename,
              releaseDate: releaseDate,
              uri: process.env.DEVICE_APP_DOWNLOAD_BASE_URL + encodeURIComponent(filename),
            }
            commit('SET_DEVICE_APP_INFO', deviceAppInfo)
            resolve(res)
          } else {
            reject(res)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  loadLatestDeviceServiceInfo({commit}) {
    return new Promise((resolve, reject) => {
      const deviceServiceInfoUrl = `${process.env.DEVICE_SERVICE_DOWNLOAD_BASE_URL}${
        process.env.DOWNLOADS_CHANNEL === 'dev' ? 'dev.yml' : 'stable.yml'
      }?&timestamp=${new Date().getTime()}`
      console.log(deviceServiceInfoUrl)
      axios
        .get(deviceServiceInfoUrl, {
          responseType: 'text',
          responseEncoding: 'utf8',
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            let version = getVersionFromYaml(res.data)
            let filename = getFileNameFromYaml(res.data)
            let releaseDate = getReleaseDateFromYaml(res.data)
            releaseDate = new Date(releaseDate)
            let deviceServiceInfo = {
              version: version,
              filename: filename,
              releaseDate: releaseDate,
              uri: process.env.DEVICE_SERVICE_DOWNLOAD_BASE_URL + encodeURIComponent(filename),
            }
            commit('SET_DEVICE_SERVICE_INFO', deviceServiceInfo)
            resolve(res)
          } else {
            reject(res)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setAvailableScales({commit, state}, payload) {
    commit('SET_AVAILABLE_SCALES', payload)
  },
  /**
   * @param {Object}
   * @param {Object} params
   * @typedef {Object} TokenVerification
   * @property {boolean} success - Connection is successful
   * @property {string} message - Connection message
   * @returns {Promise<TokenVerification>}
   */
  async connect({commit, getters, dispatch}, params) {
    commit('RESET_STATE')
    if (!params) {
      return {success: false, message: 'No authentication parameters.'}
    }
    params['user_name'] = params['user_name'].replace('+', ' ')
    commit('SET_HUB_SERVER_URL', params.device_hub_uri)
    commit('SET_WMS_INFO', {
      uri: params.wms_uri,
      company: params.company_name,
    })
    return await new Promise((resolve, reject) => {
      hubFrontendClient
        .connect(params, {commit, getters, dispatch})
        .then((res) => {
          if (res && res.success === true) {
            commit('SET_HUB_CONNECTION', {
              connected: true,
              message: 'Connected',
            })
            // Loading.hide('main-load')
            resolve(res)
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          if (process.env.DEBUGGING) console.trace(err)
          commit('SET_HUB_CONNECTION', {
            connected: false,
            message: err.toString(),
          })
          reject({success: false, message: 'Connection error', reason: err})
        })
        .finally(() => {
          commit('SET_IS_LOADING', false)
        })
    })
  },
  /**
   * @param {Object}
   * @param {Object} params
   * @typedef {Object} TokenVerification
   * @property {boolean} success - Connection is successful
   * @property {string} message - Connection message
   * @returns {Promise<TokenVerification>}
   */
  async switchUser({commit, getters, dispatch}, params) {
    commit('RESET_STATE')
    if (!params) {
      return {success: false, message: 'No authentication parameters.'}
    }
    params['user_name'] = params['user_name'].replace('+', ' ')
    commit('SET_HUB_SERVER_URL', params.device_hub_uri)
    commit('SET_WMS_INFO', {
      uri: params.wms_uri,
      company: params.company_name,
    })
    return await new Promise((resolve) => {
      dispatch('Hub/connect', params, {root: true})
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          if (process.env.DEBUGGING) console.trace('Error connecting user', err)
          resolve(err)
        })
    })
  },
  setNewLogCount({commit}, value) {
    commit('SET_NEW_LOG_COUNT', value)
  },
  setErrorLogCount({commit}, value) {
    commit('SET_ERROR_LOG_COUNT', value)
  },
  disconnect({commit}) {
    hubFrontendClient.disconnect({commit})
  },
  registerNewMachine({commit}, payload) {
    commit('SET_IS_LOADING', true)
    return hubFrontendClient
      .emitWithTimeout('claimClient', payload)
      .then((res) => {
        commit('SET_IS_LOADING', false)
        return res
      })
      .catch((err) => {
        commit('SET_IS_LOADING', false)
        return err
      })
  },
  setShowEventLogFilters({commit}, value) {
    commit('SET_SHOW_EVENT_LOG_FILTERS', value)
  },
  setSearch({commit}, value) {
    commit('SET_SEARCH', value)
  },
  setEventLogSearch({commit}, value) {
    commit('SET_EVENT_LOG_SEARCH', value)
  },
  setTagFilter({commit}, payload) {
    commit('SET_TAG_FILTER', payload)
  },
  setTypeFilter({commit}, payload) {
    commit('SET_TYPE_FILTER', payload)
  },
  setMachineFilter({commit}, payload) {
    commit('SET_MACHINE_FILTER', payload)
  },
  setSelectedClient({commit}, value) {
    commit('SET_SELECTED_CLIENT', value)
  },
  setSelectedLogSeverity({commit}, value) {
    commit('SET_LOG_SEVERITY_FILTER', value)
  },
  setLogDeviceTypeFilter({commit}, value) {
    commit('SET_LOG_DEVICE_TYPE_FILTER', value)
  },
  setEventLogMachineFilter({commit}, value) {
    commit('SET_LOG_MACHINE_FILTER', value)
  },
  loadEventLogs({commit}) {
    commit('SET_LOGS', [])
    return new Promise((resolve) => {
      hubFrontendClient
        .emitWithTimeout('getEventLogs')
        .then((logs) => {
          if (logs.length > 0) {
            commit('SET_LOGS', logs)
          }
          resolve(logs)
        })
        .catch((err) => {
          console.log(err)
          resolve([])
        })
    })
  },
  refreshTagOptions({commit}) {
    return hubFrontendClient
      .emitWithTimeout('getTagOptions')
      .then((res) => {
        if (res.data) {
          const newTags = this._vm.$formatTags(res.data)
          commit('SET_TAG_OPTIONS', newTags)
        } else {
          commit('SET_TAG_OPTIONS', [])
        }
        return res
      })
      .catch((error) => {
        return error
      })
  },
  sendIssueReport({commit}, payload) {
    return hubFrontendClient
      .emitWithTimeout('sendIssueReport', payload)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return error
      })
  },
  sendScanScales({commit}, client) {
    commit('RESET_AVAILABLE_SCALES')
    commit('SET_JOB_IS_PROCESSING', true)
    if (Object.keys(client).length) {
      return hubFrontendClient
        .emitWithTimeout('scanScales', { clientId: client.socketId })
        .then((res) => {
          if (res.success) {
            res.data.forEach(scale => {
              commit('SET_AVAILABLE_SCALES', scale)
            })
          }
          return res
        })
        .catch((error) => {
          return error
        })
    }
  },
  abortScan({commit}, client) {
    commit('SET_JOB_IS_PROCESSING', false)
    commit('SET_SCAN_TIME_PASSED', 0)
    return hubFrontendClient
      .emitWithTimeout('abortScan', { clientId: client.socketId })
      .then((res) => {
        return res
      })
      .catch((error) => {
        return error
      })
  },
  abortTest({commit}, device) {
    return hubFrontendClient
      .emitWithTimeout('abortTest', { clientId: device.socketId })
      .then((res) => {
        commit('SET_JOB_IS_PROCESSING', false)
        return res
      })
      .catch((error) => {
        commit('SET_JOB_IS_PROCESSING', false)
        return error
      })
  },
  updateClient({commit}, client) {
    if (!client.isLegacy) {
      return new Promise((resolve, reject) => {
        hubFrontendClient
          .emitWithTimeout('updateClient', client)
          .then((res) => {
            if (res.success) {
              resolve(res)
            } else {
              reject(res)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    } else {
      return false
    }
  },
  deleteDevice({commit}, payload) {
    return hubFrontendClient
      .emitWithTimeout('removeDevice', payload)
      .then((res) => {
        return res
      })
      .catch((error) => {
        console.trace(error)
        return error
      })
  },
  loadClients({ commit, state }) {
    if (state.hubServerUrl && state.hubConnection) {
      hubFrontendClient.socket.emit('loadClients')
    }
  },
  cancelDownload({commit, state}, socketId) {
    commit('SET_DOWNLOAD_PROGRESS', {progress: undefined, socketId})
    commit('SET_IS_DOWNLOADING', { socketId, value: undefined})
    hubFrontendClient.socket.emit('cancelDownload', socketId)
  },
  startScanTimer({commit}, totalTime) {
    commit('SET_SCAN_TIME_PASSED', 0)
    let timePassed = 0
    let timer = setInterval(() => {
      timePassed++
      commit('SET_SCAN_TIME_PASSED', timePassed / totalTime)
      if (timePassed / totalTime >= 1 || !state.jobIsProcessing) {
        clearInterval(timer)
        commit('SET_JOB_IS_PROCESSING', false)
      }
    }, 1000)
    setTimeout(() => {
      clearInterval(timer)
    }, 20000)
  },
  removeClient({commit}, socketId) {
    hubFrontendClient
      .emitWithTimeout('removeClient', socketId)
      .then((res) => {
        return res.success === true
      })
      .catch((err) => {
        console.trace(err)
        return false
      })
  },
  upgradeClient({commit}, { socketId, targetAppVersion, targetServiceVersion }) {
    const payload = {
      targetAppVersion: targetAppVersion,
      targetServiceVersion: targetServiceVersion,
      socketId
    }
    return new Promise((resolve, reject) => {
      hubFrontendClient
        .emitWithTimeout('upgradeClient', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          console.trace(err)
          reject({success: false})
        })
    })
  },
  setTargetServiceVersion ({commit}, version) {
    commit('SET_TARGET_SERVICE_VERSION', version)
  },
  setTargetAppVersion ({commit}, version) {
    commit('SET_TARGET_APP_VERSION', version)
  },
  upgradeMultipleClients({commit}, socketIds) {
    socketIds.forEach(socketId => {
      commit('SET_IS_DOWNLOADING', { socketId, value: true })
      commit('SET_DOWNLOAD_PROGRESS', { progress: 0, socketId })
    })
    const payload = {
      targetAppVersion: state.targetAppVersion,
      targetServiceVersion: state.targetServiceVersion,
      socketIds
    }
    return new Promise((resolve, reject) => {
      hubFrontendClient
        .emitWithTimeout('upgradeMultipleClients', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          console.trace(err)
          reject({success: false})
        })
    })
  },
  sendTestJob({commit}, payload) {
    return hubFrontendClient.emitWithTimeout('sendTestJob', payload)
  },
  testScale({commit}, { clientId, device }) {
    // Important, use device.socketId instead of client.socketId for legacy scale
    return hubFrontendClient.emitWithTimeout('testScale', { clientId, device })
  },
  updateLogs({commit}, log) {
    commit('UPDATE_LOGS', log)
    commit('SET_NEW_LOG_COUNT', 1)
    if (log.type === 'error') {
      commit('SET_ERROR_LOG_COUNT', 1)
    }
  },
  setWmsInfo({commit}, payload) {
    commit('SET_WMS_INFO', payload)
  },
}

// Mutations
const mutations = {
  SET_EVENT_LOG_PAUSED: (state, value) => {
    state.eventLogPaused = value
  },
  SET_WMS_INFO: (state, value) => {
    state.wmsInfo = value
  },
  SET_DEVICE_APP_INFO: (state, value) => {
    state.deviceAppInfo = value
  },
  SET_DEVICE_SERVICE_INFO: (state, value) => {
    state.deviceServiceInfo = value
  },
  SET_HUB_SERVER_URL: (state, value) => {
    state.hubServerUrl = value
  },
  /**
   * @param {Object} state
   * @param {Object} connection
   * @property {boolean} connected - Connection is established
   * @property {string} message - Connection message
   */
  SET_HUB_CONNECTION: (state, connection) => {
    state.hubConnection = connection
  },
  SET_SHOW_EVENT_LOG_FILTERS: (state, value) => {
    state.showEventLogFilters = value
  },
  SET_TAG_OPTIONS: (state, payload) => {
    state.allTagOptions = payload
  },
  ADD_TAG: function(state, value) {
    if (!state.allTagOptions.includes(value)) {
      state.allTagOptions.push(value)
    }
    state.allTagOptions.sort(this._vm.$compare)
  },
  SET_SEARCH: (state, value) => {
    state.search = value
  },
  SET_EVENT_LOG_SEARCH: (state, value) => {
    state.eventLogSearch = value
  },
  SET_TAG_FILTER: (state, payload) => {
    state.tagFilters = payload
  },
  SET_EVENT_LOG_MACHINE_FILTER_NEGATIVE: (state, value) => {
    if (value !== undefined) {
      state.eventLogMachineFilterNegate = value
    } else {
      state.eventLogMachineFilterNegate = !state.eventLogMachineFilterNegate
    }
  },
  SET_EVENT_LOG_DEVICE_TYPE_FILTER_NEGATIVE: (state, value) => {
  if (value !== undefined) {
    state.eventLogDeviceTypeFilterNegate = value
  } else {
    state.eventLogDeviceTypeFilterNegate = !state.eventLogDeviceTypeFilterNegate
  }
},
  SET_EVENT_LOG_SEVERITY_FILTER_NEGATIVE: (state, value) => {
  if (value !== undefined) {
    state.eventLogSeverityFiltersNegate = value
  } else {
    state.eventLogSeverityFiltersNegate = !state.eventLogSeverityFiltersNegate
  }
},
  SET_DEVICE_TAG_FILTER_NEGATIVE: (state, value) => {
    if (value !== undefined) {
      state.tagFiltersNegate = value
    } else {
      state.tagFiltersNegate = !state.tagFiltersNegate
    }
  },
  SET_TYPE_FILTER: (state, payload) => {
    state.typeFilter = payload
  },
  SET_DEVICE_TYPE_FILTER_NEGATIVE: (state, value) => {
    if (value !== undefined) {
      state.typeFilterNegate = value
    } else {
      state.typeFilterNegate = !state.typeFilterNegate
    }
  },
  SET_MACHINE_FILTER: (state, payload) => {
    state.machineFilter = payload
  },
  SET_DOWNLOAD_TEST_PROGRESS: (state, payload) => {
    state.downloadTestProgress = payload
  },
  UPDATE_CLIENT: (state, { client, socketId }) => {
    if (client) {
      state.hasClients = true
      Vue.set(state.connectedClients, socketId, client)
      if (state.machineFilter?.socketId === client.socketId) {
        state.machineFilter = client
      }
    } else {
      state.hasClients = Object.values(state.connectedClients).length > 0
      if (state.connectedClients[socketId]) {
        Vue.delete(state.connectedClients, socketId)
      }
      if (state.machineFilter?.socketId === socketId) {
        state.machineFilter = {}
      }
    }
  },
  SET_RECONNECTED_CLIENT: (state, { clientId, time }) => {
    if (time) {
      state.reconnections[clientId] = time
      const timeoutId = setTimeout(() => {
        Vue.delete(state.reconnections, clientId)
        clearTimeout(timeoutId)
      }, 12 * 60 * 60 * 1000) // clear reconnection indicator after 12 hours
    }
  },
  SET_CONNECTED_CLIENTS: (state, payload) => {
    state.hasClients = Object.keys(payload).length > 0
    state.connectedClients = payload
  },
  REFRESH_CLIENTS: (state, clientList) => {
    clientList.forEach((client) => {
      Vue.set(state.connectedClients, client.socketId, client)
    })
    state.hasClients = clientList.length > 0
  },
  SET_SELECTED_CLIENT: (state, value) => {
    state.selectedClient = value
  },
  SET_IS_LOADING_PRINTER_INFO: (state, {client, value}) => {
    if (state.connectedClients[client.socketId]) {
      const updateClient = state.connectedClients[client.socketId]
      updateClient.isLoadingPrinterInfo = value
      Vue.set(state.connectedClients, client.socketId, updateClient)
    }
  },
  SET_PRINTER_INFO: (state, {client, value}) => {
    Vue.set(state.allPrintersByClient, client.socketId, value)
  },
  RESET_STATE: (state) => {
    let newState = cleanState
    newState.wmsInfo = state.wmsInfo // Keep wms info in case the user wishes to return to the WMS via the link

    Object.assign(state, newState)
  },
  UPDATE_LOGS: (state, value) => {
    if (!value) {
      state.logs = []
      return
    }
    state.logs.push(value)
    if (state.logs.length > maxLogs) {
      state.logs.shift()
    }
  },
  SET_LOGS: (state, value) => {
    if (!value) {
      state.logs = []
      return
    }
    state.logs = value
    if (state.logs.length > maxLogs) {
      state.logs.shift()
    }
  },
  SET_IS_LOADING: (state, value) => {
    state.isLoading = value
  },
  SET_NEW_LOG_COUNT: (state, value) => {
    if (value > 0) {
      state.newLogCount += value
    } else {
      state.newLogCount = 0
    }
  },
  SET_ERROR_LOG_COUNT: (state, value) => {
    if (value > 0) {
      state.errorLogCount += value
    } else {
      state.errorLogCount = 0
    }
  },
  SET_JOB_IS_PROCESSING: (state, payload) => {
    state.jobIsProcessing = payload
  },
  SET_AVAILABLE_SCALES: (state, scale) => {
    state.availableScales = {
      ...state.availableScales,
      [scale.connectionType +
      scale.port +
      scale.baudrate +
      scale.printCommand +
      scale.rxTerminator +
      scale.txTerminator]: scale,
    }
  },
  RESET_AVAILABLE_SCALES: (state) => {
    state.availableScales = {}
  },
  SET_SCAN_TIME_PASSED: (state, value) => {
    state.scanTimePassed = value
  },
  SET_DOWNLOAD_PROGRESS: (state, {progress, socketId}) => {
    if (progress === undefined) {
      Vue.delete(state.isDownloading, socketId)
      Vue.delete(state.downloadProgress, socketId)
    } else {
      Vue.set(state.downloadProgress, socketId, progress)
      if (state.downloadProgress[socketId] >= 100) {
        Vue.set(state.isDownloading, socketId, undefined)
      }
    }
  },
  SET_TARGET_APP_VERSION: (state, value) => {
    state.targetAppVersion = value
  },
  SET_TARGET_SERVICE_VERSION: (state, value) => {
    state.targetServiceVersion = value
  },
  SET_IS_DOWNLOADING: (state, {socketId, value}) => {
    if (value === undefined) {
      Vue.delete(state.isDownloading, socketId)
      return
    }
    Vue.set(state.isDownloading, socketId, value)
  },
  SET_EVENT_LOG_SORT_ORDER: (state, value) => {
    state.isEventLogDescending = value
  },
  SET_LOG_SEVERITY_FILTER: (state, value) => {
    state.eventLogSeverityFilters = value
  },
  SET_LOG_DEVICE_TYPE_FILTER: (state, payload) => {
    state.eventLogDeviceTypeFilter = payload
  },
  SET_LOG_DEVICE_TYPE_FILTER_NEGATIVE: (state, value) => {
    if (value !== undefined) {
      state.eventLogMachineFilterNegate = value
    } else {
      state.eventLogMachineFilterNegate = !state.eventLogMachineFilterNegate
    }
  },
  SET_LOG_MACHINE_FILTER: (state, value) => {
    state.eventLogMachineFilter = value
  },
  SET_LOG_MACHINE_FILTER_NEGATIVE: (state, value) => {
    if (value !== undefined) {
      state.eventLogMachineFilterNegate = value
    } else {
      state.eventLogMachineFilterNegate = !state.eventLogMachineFilterNegate
    }
  },
  SET_STATIC_LOG_LIST: (state) => {
    state.staticLogList = [...state.logs]
  },
}

// Convert a standard or metric measurement to 'dots' for the purposes of creating ZPL
function convertValueToDots(value, unit) {
  const multipliers = {
    in: 203,
    cm: 80,
    mm: 8,
  }
  return multipliers[unit] * value
}

// Convert to Inches to mm
function getInches(value, unit) {
  const multipliers = {
    in: 1,
    cm: 2.54,
    mm: 25.4,
  }
  return Math.round((value / multipliers[unit]) * 100) / 100
}

/**
 *
 * @param data
 * @param activeLogin {{ company_name, device_hub_uri, instance_uid, jwt, logo_uri, session_expiration, user_email,
 * user_name, wms_uri }}
 * @return {{image: null, success: boolean}}
 */
function generate128BZpl(data, activeLogin) {
  const labelHeightDots = convertValueToDots(getInches(data.size.labelHeight, data.size.unit), 'in')
  const labelWidthDots = convertValueToDots(getInches(data.size.labelWidth, data.size.unit), 'in')
  const forceWidth = `^PW${Math.ceil(labelWidthDots)}` //!IMPORTANT! - This will force the ZPL label printer to adjust the expected label size to the label being printed.
  let zplData = {
    success: true,
    image: null,
  }
  let logoWidth = 32
  let barcodeRes = 2
  let outerMargin = 20
  let barcodeWidth = (46.4 + data.value.length * 11 + 13) * barcodeRes
  let allowedBarcodeWidth = labelWidthDots - outerMargin
  for (let i = 2; i < 15; i++) {
    if (allowedBarcodeWidth > (46.4 + data.value.length * 11 + 13) * i) {
      barcodeWidth = (46.4 + data.value.length * 11 + 13) * i
      barcodeRes = i
    }
  }
  if (barcodeWidth > labelWidthDots + 22) {
    zplData.success = false
    zplData.error = 'Warning: Barcode data is too long for this label size.'
    zplData.data = ''
  }

  let barcodeY =
    labelWidthDots >= 406 && labelHeightDots >= 203 ? 60 : labelWidthDots >= 243 && labelHeightDots >= 101 ? 40 : 20
  let companyIdY = labelWidthDots >= 406 && labelHeightDots >= 203 ? 40 : 20
  let height = convertValueToDots(data.size.labelHeight, data.size.unit) - (barcodeY + outerMargin + 9 * barcodeRes)
  let fieldOrigin = `^FO ${outerMargin},${barcodeY}` // x, y
  let barcodeFieldDefaults = `^BY ${barcodeRes},,${height}` //width, widthRatio, height
  let code128Barcode = `^BCN,,Y,N,N`
  let fieldData = `^FD${data.value}^FS`
  let propertyOfText = activeLogin ? `Property of ${activeLogin.company_name}` : ''
  let logoZpl = `^FO ${labelWidthDots - logoWidth - outerMargin},${outerMargin} ${
    labelWidthDots >= 406 && labelHeightDots >= 203 ? BarcodeComponents['logo_32'] : ''
  }`
  let companyZpl =
    labelWidthDots >= 406 && labelHeightDots >= 203
      ? `^FO${outerMargin},${companyIdY}^CF0,,20^FD${propertyOfText}^FS`
      : ''
  let scanDirectionsZpl =
    labelWidthDots >= 243 && labelHeightDots >= 101
      ? `^FO${outerMargin},${outerMargin},0^CF0,,20${BarcodeComponents['scan_directions']}`
      : ''

  zplData.data = `^XA
${forceWidth}
${scanDirectionsZpl}
${companyZpl}
${logoZpl}
${fieldOrigin}${barcodeFieldDefaults}${code128Barcode}
${fieldData}
^XZ`
  return zplData
}

function getSymbolSize(value) {
  if (value.typeof !== 'string') {
    value = value.toString()
  }
  const alphaNum = {
    3: 10,
    6: 12,
    10: 14,
    16: 16,
    25: 18,
    31: 20,
    43: 22,
    52: 24,
    64: 26,
    91: 32,
    127: 36,
    169: 40,
    214: 44,
    259: 48,
    304: 52,
    418: 64,
    550: 72,
    682: 80,
    862: 88,
    1042: 96,
    1222: 104,
    1573: 120,
    1954: 132,
    2335: 144,
  }
  let lowercase = value.match(/[a-z0-9]/g) ? value.match(/[a-z0-9]/g).length : 0
  let uppercase = value.match(/[A-Z]/g) ? value.match(/[A-Z]/g).length : 0
  let special = value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)
    ? value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g).length
    : 0
  let totalLength = value.length + special + lowercase + uppercase
  for (let i = 0; i < Object.values(alphaNum).length; i++) {
    let charCapacity = Object.entries(alphaNum)[i][0]
    let symbolSize = Object.entries(alphaNum)[i][1]
    if (totalLength <= charCapacity) {
      return symbolSize
    }
  }
}

function getElementCenter(width, height, elementLength) {
  return {
    x: width / 2 - elementLength / 2,
    y: height / 2 - elementLength / 2,
  }
}

/**
 * Rise over Run to get ratio
 * @param height
 * @param width
 * @return {number}
 */
function getLabelRatio(height, width) {
  return height / width
}

function generateDataMatrixZpl(data, activeInstance) {
  let companyIdY,
    dotSize,
    barcodeAreaLength,
    symbolSize,
    magnification,
    barcodeDotSize,
    symbolLength,
    barcodeY,
    barcodeX
  let dataValueZpl, dataValueX, dataValueY, spacing, logoWidth, fieldHeight, fieldWidth
  let headerFontHeight,
    headerFontWidth,
    bodyFontHeight,
    bodyFontWidth,
    fieldLines,
    propertyOfText,
    logoZpl,
    scanDirectionsY
  let logoHeaderZpl, scanDirectionsZpl, companyZpl, dataMatrixZpl

  // Initialize mutable variables
  let zplData = {
    success: true,
    image: null,
  }

  const labelHeightDots = Math.floor(convertValueToDots(getInches(data.size.labelHeight, data.size.unit), 'in'))
  const labelWidthDots = Math.floor(convertValueToDots(getInches(data.size.labelWidth, data.size.unit), 'in'))
  const outerMargin = 15
  const forceWidth = `^PW${Math.ceil(labelWidthDots)}`
  const labelStyle = getLabelRatio(labelHeightDots, labelWidthDots) >= 0.8 ? 'vertical' : 'horizontal'

  // TALLER LABELS (1:1 or greater)
  if (labelStyle === 'vertical') {
    logoWidth = 48
    headerFontHeight = 18
    headerFontWidth = headerFontHeight * 0.6
    bodyFontHeight = 24
    bodyFontWidth = bodyFontHeight * 0.8
    spacing = 0.025 * labelHeightDots
    dotSize = 0.005 * 203
    fieldLines = 2

    // Fields
    fieldWidth = labelWidthDots - 2 * outerMargin
    fieldHeight = bodyFontHeight

    symbolSize = getSymbolSize(data.value)
    barcodeAreaLength =
      getLabelRatio(labelHeightDots - 2 * fieldHeight - 2 * outerMargin, labelWidthDots - 2 * outerMargin) > 1.2
        ? labelWidthDots - 4 * outerMargin
        : labelHeightDots - 2 * fieldHeight - 2 * outerMargin - spacing - 2 * fieldHeight
    magnification = Math.floor(barcodeAreaLength / symbolSize)
    barcodeDotSize = dotSize * magnification
    symbolLength = symbolSize * barcodeDotSize

    // A - LOGO HEADER
    let logoZpl = `^FO${outerMargin},${outerMargin} ${BarcodeComponents[`logo_${logoWidth}`]}`

    // B - SCAN DIRECTIONS
    let scanDirectionsY = outerMargin
    scanDirectionsZpl = `^FO ${outerMargin +
      logoWidth +
      spacing},${scanDirectionsY},0^FB${fieldWidth},2,,L^A0,${bodyFontHeight},${bodyFontWidth}${
      BarcodeComponents['scan_directions']
    }`

    // C - COMPANY INFO
    propertyOfText = activeInstance ? `Property of ${activeInstance.company_name}` : ''
    companyIdY = scanDirectionsY + fieldHeight
    companyZpl = `^FO ${outerMargin +
      logoWidth +
      spacing},${companyIdY}^FB${fieldWidth},${fieldLines},,L^A0,${bodyFontHeight},${bodyFontWidth}^FD${propertyOfText}^FS`

    // D - DATA MATRIX
    barcodeY = outerMargin + logoWidth + spacing
    barcodeX = Math.floor(getElementCenter(labelWidthDots, labelHeightDots, symbolLength).x)
    dataMatrixZpl = `^FO ${barcodeX},${barcodeY}^BX ,${magnification},200,${symbolSize},${symbolSize}^FD${data.value}`

    // DATA VALUE
    dataValueX = outerMargin
    dataValueY = labelHeightDots - outerMargin - headerFontHeight * 2
    dataValueZpl = `^FO${dataValueX},${dataValueY},0^FB${fieldWidth},2,0,C^AA,${headerFontHeight},${headerFontWidth}^FD${data.value}^FS`
    logoHeaderZpl = `${logoZpl}${dataValueZpl}`
  }
  // WIDER LABELS
  else {
    logoWidth = 48
    fieldLines = 2
    dotSize = 0.005 * 203
    spacing = 0.025 * labelHeightDots
    barcodeAreaLength = labelHeightDots - 2 * outerMargin

    symbolSize = getSymbolSize(data.value)
    magnification = Math.floor(barcodeAreaLength / symbolSize)
    barcodeDotSize = dotSize * magnification
    symbolLength = symbolSize * barcodeDotSize

    // Fields
    fieldWidth = labelWidthDots - 2 * outerMargin - symbolLength
    fieldHeight = (labelHeightDots - 2 * outerMargin - logoWidth) / 3

    // Fonts
    headerFontHeight = 18
    headerFontWidth = Math.ceil(headerFontHeight * 0.6)
    bodyFontHeight =
      Math.ceil((fieldWidth / BarcodeComponents['scan_directions'].length) * 3) > 24
        ? 24
        : Math.ceil((fieldWidth / BarcodeComponents['scan_directions'].length) * 3)
    bodyFontWidth = Math.ceil(bodyFontHeight * 0.8)

    // A - LOGO HEADER
    logoZpl = `^FO${outerMargin},${outerMargin} ${BarcodeComponents['logo_' + logoWidth]}`

    // DATA VALUE
    dataValueX = outerMargin
    dataValueY = outerMargin + logoWidth + spacing
    dataValueZpl = `^FO${dataValueX},${dataValueY},0^FB${fieldWidth},3,,L^AA,${headerFontHeight},${headerFontWidth}^FD${data.value}^FS`
    logoHeaderZpl = `${logoZpl}${dataValueZpl}`

    // B - SCAN DIRECTIONS
    scanDirectionsY = dataValueY + fieldHeight
    scanDirectionsZpl = `^FO ${outerMargin},${scanDirectionsY},0^FB${fieldWidth},${fieldLines},,L^A0,${bodyFontHeight},${bodyFontWidth}${BarcodeComponents['scan_directions']}`

    // C - COMPANY INFO
    propertyOfText = activeInstance ? `Property of ${activeInstance.company_name}` : ''
    companyIdY = scanDirectionsY + bodyFontHeight
    companyZpl = `^FO ${outerMargin},${companyIdY}^FB${fieldWidth},${fieldLines},,L^A0,${bodyFontHeight},${bodyFontWidth}^FD${propertyOfText}^FS`

    // D - DATA MATRIX
    barcodeY = Math.floor(getElementCenter(labelWidthDots, labelHeightDots, symbolLength).y)
    barcodeX = labelWidthDots - outerMargin - symbolLength
    dataMatrixZpl = `^FO ${barcodeX},${barcodeY}^BX ,${magnification},200,${symbolSize},${symbolSize}^FD${data.value}`
  }

  // COMBINED
  zplData.data = `^XA
${forceWidth}
${logoHeaderZpl}
${scanDirectionsZpl}
${companyZpl}
${dataMatrixZpl}
^XZ`
  return zplData
}

async function getBarcodeImage(url, zpl) {
  return await new Promise((resolve) => {
    axios({
      method: 'post',
      transformRequest: [
        (data, headers) => {
          delete headers.post.Authorization
          return data
        },
      ],
      url: url,
      data: zpl,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      responseType: 'arraybuffer',
    })
      .then((response) => {
        if (response.status === 200) {
          const buffer64 = Buffer.from(response.data, 'binary').toString('base64')
          resolve(buffer64)
        } else {
          resolve(null)
        }
      })
      .catch((error) => {
        if (process.env.DEBUGGING) console.log('barcode generation error', error)
        resolve(null)
      })
  })
}

// Local helpers
function getVersionFromYaml(yaml) {
  let parentRegex = /^version:\s(\d*\.\d*\.\d*)/m
  let version = yaml.match(parentRegex)
  return version[1]
}

function getFileNameFromYaml(yaml) {
  let parentRegex = /^\s*-?\s?url:\s(.*)/m
  let filename = yaml.match(parentRegex)
  return filename[1]
}

function getReleaseDateFromYaml(yaml) {
  let parentRegex = /^\s*-?\s?releaseDate:\s'(.*)'/m
  let releaseDate = yaml.match(parentRegex)
  return releaseDate[1]
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
